<template>
	<div style="padding: 10px 15px;">
		<div style="height: 30px;"></div>
		<div v-for="item of order.children" style=" margin-bottom: 10px;">

			<div style="float: left; width: 60px; height: 60px;">
				<img :src="item.headimg" style="width: 100%; height: 100%;" />
			</div>
			<div style="margin-left: 80px; height: 50px; padding: 5px 0;">
				<input type="text" style="height: 50px; border: 0; width: 90%; padding: 0 4%; " v-model="item.comment" placeholder="对商品的评价、看法或使用心得" />
			</div>
		</div>
		<div style="height: 50px;"></div>
		<div class="flexcenter btnfabiao" @click="SendComment">发表评价</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				order: {}
			}
		},
		created() {
			if(sessionStorage.getItem("comment")) {
				this.order = JSON.parse(sessionStorage.getItem("comment"))
				for(let item of this.order.children) {
					item.comment = "";
				}
			}
		},
		methods: {
			SendComment() {
				var comments = [];
				for(let item of this.order.children) {
					if(item.comment==""){
						item.comment="好评！";
					}
					
					comments.push({
						goodid: item.goodid,
						orderid: item.orderid,
						comments: encodeURIComponent(item.comment),
						star: 5,
						image: ""
					})
				}

				this.$store.dispatch("POST", {
					url: "/api/wx/?action=comment",
					query: {
						comment:JSON.stringify(comments)
					}
				}).then(result => {
					$Toast("评价成功，感谢您的参与！");
					history.back(-1)
				})

			}
		}
	}
</script>

<style scoped="scoped">
	.btnfabiao {
		height: 40px;
		width: 80%;
		background: #26a1ff;
		color: #fff;
		margin: 0 auto;
		border-radius: 4px;
	}
	
	.btnfabiao:active {
		background: #1180d5;
	}
</style>